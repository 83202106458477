$mobileAppBarHeight: 88px;

header.header {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  top: 0;
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 9;

  @screen md {
    position: relative;
  }
  .container {
    display: flex;
    margin: auto;
    height: $mobileAppBarHeight;
    padding: 0 1rem 0 2.5rem;

    @screen md {
      padding: 0 2.5rem;
      max-width: 1300px;
    }
    align-items: center;
    > div {
      display: flex;
      // flex: 1;
      gap: 1rem;
      &.center {
        flex: 1;
      }
      &.right {
        justify-content: flex-end;

        // display: none;

        @screen md {
          // display: block;
        }
      }

      &.appBarMenu {
        display: none;

        @screen md {
          display: block;
        }
        .appBarMenuList {
          display: flex;

          .appBarMenuListItem {
            font-size: 1.125rem;
            margin-right: 34px;
            cursor: pointer;

            &:first-child {
              margin-left: 20px;
            }

            &:hover,
            > a:hover {
              @apply text-y-600;
            }

            .active {
              @apply text-y-600;
            }
          }
        }
      }
    }
  }
}

.appBarCenter {
  margin: auto;
  display: flex;
  gap: 1rem;
  a {
    width: 4rem;
    height: 3.884375rem;
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    // 실제 render 시간차 있음
    transition: fill 0.3s, color 0.2s;
    fill: #aaaaaa;
    color: #aaaaaa;
    &:hover {
      text-decoration: none;
      color: inherit;
    }
    > .iconWrapper {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      &:hover {
        fill: #442d00;
        color: #442d00;
      }
    }
    > .title {
      outline: none;
      display: block;
      margin-bottom: 1px;
      font-size: 0.75rem;
      line-height: 1.4375rem;
    }
    &.active {
      background: #f4f0e6;
      fill: #442d00;
      color: #442d00;
    }
  }
}

.appBarRight {
  display: flex;
  align-items: center;
  position: relative;
  gap: 1rem;
  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    border-color: #aaaaaa;
    stroke: #aaaaaa;
    border-width: 2px;
    &:hover {
      border-color: #333;
      stroke: #333;
    }
  }
}

.logo {
  img {
    height: 1.5rem;
  }
}

// Dropdown
.hamburgerWrapper {
  position: relative;
}

.dropdownWrapper {
  @apply border-[2px] border-solid border-k-200;
  background: #fff;
  position: absolute;
  min-width: 164px;
  top: 100%;
  right: 0;
  border-radius: 4px;
  z-index: 2;
}

.dropdownItem {
  display: grid;
  align-items: center;
  padding: 14px;
  font-weight: bold;
  &:hover {
    @apply bg-y-200;
    cursor: pointer;
  }

  > a {
    &:hover {
      @apply text-y-900;
    }
  }
}

.mobileHamburgurMenu {
  position: fixed;
  background: #fff;
  width: 100vw;
  height: calc(100vh - $mobileAppBarHeight);
  right: 0;
  top: $mobileAppBarHeight;
  z-index: 9;
}

.hamburgerProfile {
  border-top: 1px solid #eee;
  padding-top: 1.5rem;

  .profileTop {
    display: flex;
    padding: 18px;
    align-items: center;
  }

  .profileBottom {
    display: flex;
    padding: 18px 0;

    .myClassroom,
    .myPage {
      width: 50%;

      .iconLinkWithLabel {
        a {
          display: flex;
          flex-direction: column;
          align-items: center;

          h4 {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}

.hamburgerMenuLink {
  ul {
    li {
      padding: 24px 20px;

      a {
        font-size: 16px;
      }
    }
  }
}

.dividerVertical {
  width: 1px;
  height: 28px;
  background-color: rgb(234, 235, 237);
}

.dividerHorizontal {
  width: 100%;
  height: 12px;
  background-color: rgb(234, 235, 237);
}

.hamburgerMenuFooter {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;

  padding: 24px 20px;
  border-top: 1px solid rgb(234, 235, 237);
  text-decoration: underline;
  font-size: 12px;
}
